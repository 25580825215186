<template>
  <v-dialog v-model="dialog" width="1000" retain-focus @click:outside="cancel" @keydown.esc="cancel">
    <v-toolbar flat dark color="primary">
      <v-btn icon dark @click="cancel">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>Configurar Build</v-toolbar-title>
      <v-spacer />
    </v-toolbar>
    <v-card flat class="pa-4">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-alert v-if="apps.length > 10" type="error" block outlined dense>São possíveis somente 10 aplicativos por build.</v-alert>
        <v-container fluid>
          <v-row no-gutters>
            <v-col cols="6" class="pr-4">
              <div class="mb-6">
                <span>Selecione a(s) plataforma(s):</span>
                <v-checkbox
                  v-model="platforms"
                  :disabled="platforms.includes('ios') && massive_build"
                  value="android"
                  prepend-icon="android"
                  label="Android"
                  color="success"
                  hide-details
                />
                <v-checkbox
                  v-model="platforms"
                  :disabled="platforms.includes('android') && massive_build"
                  value="ios"
                  prepend-icon="mdi-apple"
                  label="IOS"
                  color="error"
                  hide-details
                />
              </div>
              <v-radio-group v-model="branchTag" mandatory row>
                <v-radio value="branch" label="Branch" />
                <v-radio value="tag" label="Tag" />
              </v-radio-group>
              <div>
                <mf-text-input v-model="branchTagValue" :label="branchTag === 'branch' ? 'Branch' : 'Tag'" :rules="[notEmpty]" outlined />
              </div>
              <div>
                <span>Selecione o comando fastlane:</span>
                <v-select
                  v-model="build_params.fastlane_lane"
                  :rules="[notEmpty]"
                  :items="availableCommands"
                  item-text="name"
                  item-value="value"
                  label="Comando"
                  outlined
                />
              </div>
            </v-col>
            <v-col cols="6" class="pl-4">
              <div class="ml-2 mb-6">
                <v-row no-guttes>
                  <v-col cols="6">
                    <mf-toggle v-model="build_params.test" color="#334A58" :disabled="test_block" hide-details class="mr-auto" label="Teste" />
                    <mf-toggle v-model="build_params.api_beta" color="#334A58" :disabled="api_beta_block" hide-details class="mr-auto" label="Beta" />
                  </v-col>
                  <v-col cols="6">
                    <mf-toggle
                      v-model="build_params.increase_major"
                      color="#334A58"
                      :disabled="increase_major_block"
                      hide-details
                      class="mr-auto"
                      label="INCREASE_MAJOR"
                    />
                    <mf-toggle
                      v-model="build_params.increase_feature"
                      color="#334A58"
                      :disabled="increase_feature_block"
                      hide-details
                      class="mr-auto"
                      label="INCREASE_FEATURE"
                    />
                    <mf-toggle
                      v-model="build_params.increase_fix"
                      color="#334A58"
                      :disabled="increase_fix_block"
                      hide-details
                      class="mr-auto"
                      label="INCREASE_FIX"
                    />
                    <mf-toggle
                      v-model="build_params.increase_client"
                      color="#334A58"
                      :disabled="increase_client_block"
                      hide-details
                      class="mr-auto"
                      label="INCREASE_CLIENT"
                    />
                  </v-col>
                </v-row>
              </div>
              <div class="pt-6">
                <span>Changelog:</span>
                <mf-text-area-input v-model="build_params.changelog" no-resize :rules="[notEmpty]" outlined />
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-form>

      <v-container fluid>
        <v-alert v-if="massive_build" type="info" block outlined dense
          >Build massivo disponível somente para uma plataforma por vez, favor selecionar a plataforma desejada.</v-alert
        >
        <v-row no-gutters>
          <v-col cols="6">
            <v-checkbox
              v-model="massive_build"
              :disabled="apps.length ? true : false"
              prepend-icon="mdi-alert-circle"
              label="Envio massivo"
              color="primary"
              hide-details
              class="pb-6"
            />
          </v-col>
        </v-row>

        <div v-if="apps.length" class="pt-6 pb-4">
          <h4 style="text-align: center">Aplicativos selecionados:</h4>
          <v-row style="margin: 10px auto">
            <v-chip v-for="app in apps" :key="app._id" style="margin: 2px auto; justify-content: space-between" close @click:close="removeApp(app._id)">
              {{ app.channels.app.build.app_name.replace(/"/g, '') }}
            </v-chip>
          </v-row>
        </div>
      </v-container>

      <v-divider />
      <v-card-actions class="mb-2 mt-4">
        <v-btn :disabled="loading" color="error" outlined @click="cleanFields">Limpar campos</v-btn>
        <v-spacer />
        <v-btn :disabled="!valid || loading || (!apps.length && !massive_build)" color="primary" @click="buildManager">Enviar build para fila</v-btn>
      </v-card-actions>
      <v-progress-linear v-if="loading" indeterminate color="primary" />
    </v-card>

    <!-- massive_build dialog -->
    <massive-build-modal :dialog.sync="massiveBuildDialog" :build-params.sync="build_params" :platforms.sync="platforms" />
  </v-dialog>
</template>

<script>
import { MUTATION_START_BUILD } from '@/modules/app/graphql/index.js'

export default {
  components: {
    MassiveBuildModal: () => import('@/modules/app/views/components/MassiveBuildModal.vue')
  },
  props: {
    dialog: {
      type: Boolean,
      default: () => false
    },
    apps: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    valid: false,
    loadingAction: false,
    massiveBuildDialog: false,
    platforms: [],
    massive_build: false,
    branchTag: '',
    branchTagValue: '',
    increaseType: '',
    build_params: {
      app_list: [],
      fastlane_lane: '',
      workflow_id: '',
      changelog: '',
      test: false,
      api_beta: false,
      increase_major: false,
      increase_feature: false,
      increase_fix: false,
      increase_client: false
    },
    api_beta_block: false,
    increase_major_block: false,
    increase_feature_block: false,
    increase_fix_block: false,
    increase_client_block: false,
    test_block: false,
    notEmpty: v => !!v || 'Campo obrigatório'
  }),
  computed: {
    availableCommands() {
      return [
        { name: 'Criação de app | Etapa 1', value: 'app_create_step_one' },
        { name: 'Criação de app | Etapa 2', value: 'app_create_step_two' },
        { name: 'Atualização de app | Binários', value: 'app_update_binary' },
        { name: 'Atualização completa de app', value: 'app_update_complete' },
        { name: 'Atualização CodePush', value: 'app_update_codepush' }
      ]
    },
    loading() {
      return this.loadingAction
    }
  },
  watch: {
    massive_build() {
      if (this.massive_build) this.platforms = []
    },
    'build_params.api_beta'(val) {
      if (val?.toString() === 'true') {
        this.build_params.increase_major = false
        this.build_params.increase_feature = false
        this.build_params.increase_fix = false
        this.build_params.increase_client = false
        this.build_params.test = true

        this.increase_major_block = true
        this.increase_feature_block = true
        this.increase_fix_block = true
        this.increase_client_block = true
        this.test_block = true
      } else {
        this.increase_major_block = false
        this.increase_feature_block = false
        this.increase_fix_block = false
        this.increase_client_block = false
        this.test_block = false
      }
    },
    'build_params.increase_major'(val) {
      if (val?.toString() === 'true') {
        this.build_params.increase_feature = false
        this.build_params.increase_fix = false
        this.build_params.increase_client = false
        this.build_params.api_beta = false

        this.increase_feature_block = true
        this.increase_fix_block = true
        this.increase_client_block = true
        this.api_beta_block = true
      } else {
        this.increase_feature_block = false
        this.increase_fix_block = false
        this.increase_client_block = false
        this.api_beta_block = false
      }
    },
    'build_params.increase_feature'(val) {
      if (val?.toString() === 'true') {
        this.build_params.increase_major = false
        this.build_params.increase_fix = false
        this.build_params.increase_client = false
        this.build_params.api_beta = false

        this.increase_major_block = true
        this.increase_fix_block = true
        this.increase_client_block = true
        this.api_beta_block = true
      } else {
        this.increase_major_block = false
        this.increase_fix_block = false
        this.increase_client_block = false
        this.api_beta_block = false
      }
    },
    'build_params.increase_fix'(val) {
      if (val?.toString() === 'true') {
        this.build_params.increase_major = false
        this.build_params.increase_feature = false
        this.build_params.increase_client = false
        this.build_params.api_beta = false

        this.increase_major_block = true
        this.increase_feature_block = true
        this.increase_client_block = true
        this.api_beta_block = true
      } else {
        this.increase_major_block = false
        this.increase_feature_block = false
        this.increase_client_block = false
        this.api_beta_block = false
      }
    },
    'build_params.increase_client'(val) {
      if (val?.toString() === 'true') {
        this.build_params.increase_major = false
        this.build_params.increase_feature = false
        this.build_params.increase_fix = false
        this.build_params.api_beta = false

        this.increase_major_block = true
        this.increase_feature_block = true
        this.increase_fix_block = true
        this.api_beta_block = true
      } else {
        this.increase_major_block = false
        this.increase_feature_block = false
        this.increase_fix_block = false
        this.api_beta_block = false
      }
    }
  },
  methods: {
    cancel() {
      this.cleanFields()
      this.$emit('close')
    },
    removeApp(accountId) {
      const appToRemove = this.apps.filter(account => account?._id === accountId)
      this.apps.splice(this.apps.indexOf(appToRemove[0]), 1)
      this.$emit('update:apps', this.apps)
    },
    cleanFields() {
      this.loadingAction = true
      this.build_params.app_list = []
      this.build_params.branch = ''
      this.build_params.fastlane_lane = ''
      this.build_params.workflow_id = ''
      this.build_params.changelog = ''
      this.build_params.test = false
      this.platforms = []
      this.massive_build = false

      setTimeout(() => {
        this.loadingAction = false
      }, 1000)
    },
    setAppToList() {
      this.apps.forEach(acc => {
        this.build_params.app_list.push({ app_name: acc?.channels?.app?.build?.app_name, _id: acc?._id })
      })
    },
    async buildManager() {
      if (!this.$refs.form.validate() || !this.platforms.length) {
        return this.$snackbar({ message: 'Falha ao validar campos', snackbarColor: '#F44336' })
      }

      if (this.massive_build) {
        this.massiveBuildDialog = true
        return
      }

      this.loadingAction = true
      try {
        this.setAppToList()
        await this.platforms.forEach(async platform => {
          await this.sendBuild(platform)
        })

        setTimeout(() => {
          this.$snackbar({ message: 'Builds enviadas para a fila com sucesso', snackbarColor: '#2E7D32' })
          this.cancel()
          this.$router.push('/app-logs')
        }, 4000)
      } catch (err) {
        this.$snackbar({ message: 'Falha ao enviar build para a fila', snackbarColor: '#F44336' })
      }
    },
    async sendBuild(platform) {
      const buildParams = {
        ...this.build_params,
        [this.branchTag]: this.branchTagValue
      }

      try {
        const res = await this.$apollo.mutate({
          mutation: MUTATION_START_BUILD,
          variables: {
            build_params: {
              ...buildParams,
              workflow_id: `create_${platform}`
            }
          },
          context: {
            headers: {
              authorization: localStorage.getItem('session_id')
            },
            uri: this.$microservicesUrls['app_build']
          }
        })

        console.log(`${platform}_build: `, res?.data?.startBuild?.message)
      } catch (err) {
        console.log(err?.message)
      }
    }
  }
}
</script>
